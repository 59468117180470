import { createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _this = this;
  var _component_b_col = _resolveComponent("b-col");
  var _component_b_row = _resolveComponent("b-row");
  var _directive_t = _resolveDirective("t");
  return _openBlock(), _createBlock(_component_b_row, {
    "align-h": "center"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_b_col, {
        cols: "12",
        "class": "text-center"
      }, {
        "default": _withCtx(function () {
          return [_withDirectives((_openBlock(), _createElementBlock("h2", null, _cache[0] || (_cache[0] = [_createTextVNode("x - Safety Data Sheet Selector Tool")]))), [[_directive_t, {
            path: 'ml_112202',
            locale: _this.$i18n.locale
          }]])];
        }),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "8",
        sm: "6",
        md: "5",
        lg: "4",
        xl: "4",
        "class": "text-center"
      }, {
        "default": _withCtx(function () {
          return [_withDirectives((_openBlock(), _createElementBlock("p", null, _cache[1] || (_cache[1] = [_createTextVNode(" Our Safety Data Sheet (SDS) Library allows you to search and find the SDS you need in the language you select. ")]))), [[_directive_t, {
            path: 'ml_112204',
            locale: _this.$i18n.locale
          }]])];
        }),
        _: 1
      })];
    }),
    _: 1
  });
}