
import { createI18n } from 'vue-i18n'
import axios from 'axios'
import english from '@/locales/en-US.json'

const defaultLang = 'en-US', defaultMessages = { 'en-US': english }

const numberFormatsTemplate = {
  '2dec': { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  '3dec': { minimumFractionDigits: 3, maximumFractionDigits: 3 },
  '4dec': { minimumFractionDigits: 4, maximumFractionDigits: 4 }
}

const dateTimeFormatsTemplate = {
  short: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  },
  med: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false
  },
  long: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric'
  }
}

const numberFormats = { 'en-US': numberFormatsTemplate },// eslint-disable-line
  datetimeFormats = { 'en-US': dateTimeFormatsTemplate };// eslint-disable-line

// Set new locale.
export async function setLocale(locale) {
  if (!i18n.global.availableLocales.includes(locale)) {
    return import(/* webpackChunkName: "lang-[request]" */ `./locales/${locale}`)
      .then(msgs => {
        return intialLanguage(locale, msgs);
      })
      .catch(() => {
        return intialLanguage(defaultLang, defaultMessages['en-US']);
      })
  }
}

const intialLanguage = (lang, messages) => { // eslint-disable-line
  numberFormats[lang] = numberFormatsTemplate
  datetimeFormats[lang] = dateTimeFormatsTemplate
  i18n.global.setLocaleMessage(lang, messages);
  i18n.global.locale.value = lang;
  return setI18nLanguage(lang)
}

const setI18nLanguage = (lang) => {
  axios.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html')?.setAttribute('lang', lang)
  return lang
}

export const i18n = createI18n({
  globalInjection: true,
  legacy: false, //default is true, you must set `false`, to use Composition API
  locale: defaultLang,
  fallbackLocale: defaultLang,
  preserveDirectiveContent: true,
  allowComposition: true,
  datetimeFormats,
  numberFormats,
  messages: defaultMessages,
  missingWarn: false, // Composition  [intlify] Not found 'ml_107448' key in 'en' locale messages.
})
