export default {
  name: 'Footer',
  components: {},
  props: ['country', 'localeCode'],
  computed: {},
  mounted: function mounted() {},
  methods: {
    getTelephone: function getTelephone() {
      return this.country.footer && this.country.footer.phone ? this.country.footer.phone : 'tel:18005550213';
    },
    getEmail: function getEmail() {
      return this.country.footer && this.country.footer.mail ? this.country.footer.mail : 'mailto:info@airproducts.com';
    },
    getFBLink: function getFBLink() {
      return this.country.footer && this.country.footer.facebook ? this.country.footer.facebook : 'https://www.facebook.com/airproducts';
    },
    getTwitterLink: function getTwitterLink() {
      return this.country.footer && this.country.footer.twitter ? this.country.footer.twitter : 'https://twitter.com/AirProducts';
    },
    getLinkedinLink: function getLinkedinLink() {
      return this.country.footer && this.country.footer.linkedin ? this.country.footer.linkedin : 'https://www.linkedin.com/company/air-products';
    },
    getInstagramLink: function getInstagramLink() {
      return this.country.footer && this.country.footer.instagram ? this.country.footer.instagram : 'https://www.instagram.com/airproductsglobal';
    },
    getLogoUrl: function getLogoUrl() {
      if (this.country.countrycode === 'IL' && this.localeCode === 'he-IL') {
        return 'images/ap_logo_il_he.png';
      }
      return this.country.logo ? this.country.logo.src : 'https://www.airproducts.com/-/media/airproducts/air-products-logo.png';
    },
    getYoutubeLink: function getYoutubeLink() {
      return this.country.footer && this.country.footer.youtube ? this.country.footer.youtube : 'http://www.youtube.com/airproducts';
    }
  }
};