import vueRecaptcha from 'vue3-recaptcha2';
import { $api } from '@/plugins/axios';
export default {
  name: 'ContactForm',
  components: {
    vueRecaptcha: vueRecaptcha
  },
  props: ['shouldRender'],
  computed: {
    enableDownload: function enableDownload() {
      return {
        agree: this.agreeChecked !== true || this.fullname.trim() === '' || this.company.trim() === '' || this.emailaddress.trim() === '' || this.telephone.trim() === '' || this.recaptchaVerified === ''
      };
    }
  },
  mounted: function mounted() {},
  data: function data() {
    return {
      fullname: '',
      company: '',
      emailaddress: '',
      telephone: '',
      agreeChecked: false,
      recaptchaVerified: '',
      chosen: '',
      isDownloading: false,
      isInvalidEmail: false
    };
  },
  methods: {
    getPrivacyPolicy: function getPrivacyPolicy(str) {
      var s = this.$t(str);
      var privacyURL = this.$store.state.userCountryObject.privacypolicy;
      return s.replace('<a href="">', '<a href="' + privacyURL + '" target="_blank">');
    },
    getTranslatedString: function getTranslatedString(str) {
      return this.$t(str);
    },
    getMatchingMaterial: function getMatchingMaterial() {
      var _this = this;
      var material;
      var materialToPass;
      switch (this.$store.state.country) {
        case 'OTH':
          {
            material = this.$store.state.prospectResponse.find(function (a) {
              return a.MD === _this.$store.state.materialNo && a.SO.startsWith('GB');
            });
            break;
          }
        case 'HU':
          {
            material = this.$store.state.prospectResponse.find(function (a) {
              return a.MD === _this.$store.state.materialNo && a.SO.startsWith('CZ');
            });
            break;
          }
        default:
          {
            material = this.$store.state.prospectResponse.find(function (a) {
              return a.MD === _this.$store.state.materialNo && a.SO.startsWith(_this.$store.state.country);
            });
            break;
          }
      }
      //if material not found 
      //first check for GB salesOrg
      if (!material) {
        material = this.$store.state.prospectResponse.find(function (a) {
          return a.MD === _this.$store.state.materialNo && a.SO.startsWith('GB');
        });
      }
      //else pick the first from the list
      if (!material) {
        material = this.$store.state.prospectResponse.find(function (a) {
          return a.MD === _this.$store.state.materialNo;
        });
      }
      materialToPass = {
        Country: this.$store.state.country === 'OTH' ? 'GB' : this.$store.state.country,
        Language: this.$store.state.language.toUpperCase(),
        Material: material.MN,
        SalesOrg: material.SO,
        MaterialDescription: material.MD
      };
      return materialToPass;
    },
    submitContactForm: function submitContactForm() {
      if (this.validateEmail()) {
        var material = this.getMatchingMaterial();
        this.isDownloading = true;
        this.emailSDS(material);
        this.$emit('contactSelected', this.chosen);
        this.$refs.recaptcha.reset();
        this.recaptchaVerified = '';
      }
    },
    getProspectData: function getProspectData() {
      return {
        Name: this.fullname,
        Company: this.company,
        Email: this.emailaddress,
        Telephone: this.telephone,
        UserCountry: this.$store.state.userCountryObject.countrycode,
        UserLanguage: this.$store.state.userLanguage
      };
    },
    getErrorMessage: function getErrorMessage(msg) {
      switch (msg) {
        case 'INVALID_INPUT':
          return 'ml_114074';
        case 'EXCEPTION_OCCURED':
          return 'ml_114077';
        case 'RECAPTCHA_VALIDATION_FAILED':
          return 'ml_114076';
        case 'NO_SDS_FOUND':
          return 'ml_114075';
        default:
          return msg;
      }
    },
    emailSDS: function emailSDS(material) {
      var _this2 = this;
      return new Promise(function (resolve, reject) {
        var url = 'sap/doc';
        var request = {
          MaterialData: material,
          ProspectData: _this2.getProspectData(),
          Token: _this2.recaptchaVerified
        };
        $api.post(url, request).then(function (response) {
          if (response.data.isSuccess) {
            alert(response.data.emailMsg === 'Email sent successfully' ? _this2.$t('ml_114078') : _this2.$t('ml_114084'));
            _this2.resetToCustJourney();
          } else {
            alert(_this2.$t(_this2.getErrorMessage(response.data.errorMsg)));
          }
          resolve();
        })["catch"](function (err) {
          console.log(err);
        }).then(function () {
          _this2.isDownloading = false;
        });
      });
    },
    resetToCustJourney: function resetToCustJourney() {
      this.$router.push({
        query: {
          ct: this.$store.state.userCountryObject.countrycode,
          lo: this.$store.state.userLanguage,
          sdslo: this.$store.state.language
        }
      });
      this.$router.go();
    },
    validateEmail: function validateEmail() {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emailaddress)) {
        return true;
      }
      this.isInvalidEmail = true;
      return false;
    },
    userAgrees: function userAgrees(event) {
      this.agreeChecked = event.target.checked;
    },
    onCaptchaVerified: function onCaptchaVerified(recaptchaToken) {
      this.recaptchaVerified = recaptchaToken;
    },
    onCaptchaExpired: function onCaptchaExpired() {
      this.recaptchaVerified = '';
    }
  }
};