import Hero from './Hero';
import Heading from './Heading';
import ProductSearch from './ProductSearch';
import CountrySelect from './CountrySelect';
import LanguageSelect from './LanguageSelect';
import Download from './Download';
import ContactForm from './ContactForm';
import { $api } from '@/plugins/axios';
export default {
  name: 'Layout',
  components: {
    Hero: Hero,
    Heading: Heading,
    ProductSearch: ProductSearch,
    CountrySelect: CountrySelect,
    LanguageSelect: LanguageSelect,
    Download: Download,
    ContactForm: ContactForm
  },
  props: ['country'],
  mounted: function mounted() {
    this.loadSDSCountriesLanguages();
  },
  data: function data() {
    return {
      currentPanel: 'productsearch',
      options: {
        product: '',
        isMaterialName: '',
        country: '',
        language: '',
        name: '',
        company: '',
        email: '',
        phone: ''
      },
      displayCountryLanguage: false
    };
  },
  methods: {
    loadSDSCountriesLanguages: function loadSDSCountriesLanguages() {
      var _this = this;
      return new Promise(function (resolve, reject) {
        var payload = JSON.stringify({
          TableName: 'prospect-materials',
          ExpressionAttributeValues: {
            ':m': {
              S: 'COUNTRIES_LANGUAGES_JSON'
            }
          },
          KeyConditionExpression: 'PK = :m'
        });
        $api.post('prospectfind', payload).then(function (response) {
          var CoLn = undefined;
          if (response.data.Count > 0) {
            CoLn = JSON.parse(response.data.Items[0].UNIQUE_COUNTRIES_LANGUAGES.S);
          }
          //added Other country in list
          CoLn.Countries.push('OTH');
          _this.$store.commit('setSdsCountries', CoLn.Countries);
          _this.$store.commit('setSdsLanguages', CoLn.Languages);
          _this.displayCountryLanguage = true;
          resolve(CoLn);
        })["catch"](function (err) {
          // eslint-disable-next-line no-console
        }).then(function () {});
      });
    },
    selectProduct: function selectProduct(product, isMaterialName) {
      this.options.product = product;
      this.options.isMaterialName = isMaterialName;
      this.currentPanel = 'countryselect';
    },
    selectCountry: function selectCountry(country) {
      this.options.country = country;
      this.currentPanel = 'languageselect';
    },
    selectLanguage: function selectLanguage(language) {
      this.options.language = language;
      if (this.options.isMaterialName) {
        this.currentPanel = 'contactform';
      } else {
        this.currentPanel = 'download';
      }
    },
    updateProspectMaterials: function updateProspectMaterials() {
      var _this2 = this;
      var co = this.$store.state.country;
      var ln = this.$store.state.language.toUpperCase();
      co = co === 'OTH' ? 'GB' : co;
      return new Promise(function (resolve, reject) {
        var config = JSON.stringify({
          TableName: 'prospect-materials',
          ExpressionAttributeValues: {
            ':m': {
              S: co + '-' + ln
            }
          },
          KeyConditionExpression: 'PK = :m'
        });
        $api.post('prospectfind', config).then(function (response) {
          var materials = undefined;
          var distinctMaterialNameList = [];
          if (response.data.Count > 0) {
            materials = JSON.parse(response.data.Items[0].MATERIALS_JSON.S);
            materials.forEach(function (item) {
              if (!distinctMaterialNameList.find(function (indexItem) {
                return indexItem === item.MD;
              })) {
                distinctMaterialNameList.push(item.MD);
                distinctMaterialNameList.sort();
              }
            });
          }
          _this2.$store.commit('setProspectResponse', materials);
          _this2.$store.commit('setFilteredResponseData', distinctMaterialNameList);
          resolve(distinctMaterialNameList);
        })["catch"](function (err) {
          // eslint-disable-next-line no-console
          console.log(err);
        }).then(function () {});
      });
    },
    selectDownload: function selectDownload() {},
    selectContactDetails: function selectContactDetails(name, company, email, phone) {
      this.options.name = name;
      this.options.company = company;
      this.options.email = email;
      this.options.phone = phone;
      alert(name);
    }
  }
};