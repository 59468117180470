import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, vModelText as _vModelText, createCommentVNode as _createCommentVNode, vShow as _vShow, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = ["placeholder"];
var _hoisted_3 = ["placeholder"];
var _hoisted_4 = ["placeholder"];
var _hoisted_5 = {
  key: 0,
  style: {
    "color": "red"
  }
};
var _hoisted_6 = ["placeholder"];
var _hoisted_7 = {
  "class": "form-group mb-3"
};
var _hoisted_8 = ["innerHTML"];
var _hoisted_9 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _this = this;
  var _component_b_row = _resolveComponent("b-row");
  var _component_b_col = _resolveComponent("b-col");
  var _component_vue_recaptcha = _resolveComponent("vue-recaptcha");
  var _directive_t = _resolveDirective("t");
  return $props.shouldRender == 'prospect' ? (_openBlock(), _createElementBlock("span", _hoisted_1, [_createVNode(_component_b_row, null, {
    "default": _withCtx(function () {
      return _cache[7] || (_cache[7] = [_createElementVNode("br", null, null, -1)]);
    }),
    _: 1
  }), _createVNode(_component_b_row, {
    "align-h": "center"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_b_col, {
        cols: "1"
      }, {
        "default": _withCtx(function () {
          return _cache[8] || (_cache[8] = [_createTextVNode(" ")]);
        }),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "8",
        md: "6",
        lg: "4",
        xl: "4",
        "class": "text-center"
      }, {
        "default": _withCtx(function () {
          return [_withDirectives((_openBlock(), _createElementBlock("h3", null, _cache[9] || (_cache[9] = [_createTextVNode("Download Now")]))), [[_directive_t, {
            path: 'ml_112212',
            locale: _this.$i18n.locale
          }]])];
        }),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "1"
      }, {
        "default": _withCtx(function () {
          return _cache[10] || (_cache[10] = [_createTextVNode(" ")]);
        }),
        _: 1
      })];
    }),
    _: 1
  }), _createVNode(_component_b_row, null, {
    "default": _withCtx(function () {
      return _cache[11] || (_cache[11] = [_createElementVNode("br", null, null, -1)]);
    }),
    _: 1
  }), _createVNode(_component_b_row, {
    "align-h": "center"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_b_col, {
        cols: "10",
        md: "6",
        xl: "4"
      }, {
        "default": _withCtx(function () {
          return [_withDirectives(_createElementVNode("input", {
            type: "text",
            id: "yourname",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return $data.fullname = $event;
            }),
            "class": "dropdown-input",
            placeholder: _this.getTranslatedString('ml_112214')
          }, null, 8, _hoisted_2), [[_vModelText, $data.fullname]]), _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1))];
        }),
        _: 1
      })];
    }),
    _: 1
  }), _createVNode(_component_b_row, {
    "align-h": "center"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_b_col, {
        cols: "10",
        md: "6",
        xl: "4"
      }, {
        "default": _withCtx(function () {
          return [_withDirectives(_createElementVNode("input", {
            type: "text",
            id: "company",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
              return $data.company = $event;
            }),
            "class": "dropdown-input",
            placeholder: _this.getTranslatedString('ml_112215')
          }, null, 8, _hoisted_3), [[_vModelText, $data.company]]), _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1))];
        }),
        _: 1
      })];
    }),
    _: 1
  }), _createVNode(_component_b_row, {
    "align-h": "center"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_b_col, {
        cols: "10",
        md: "6",
        xl: "4"
      }, {
        "default": _withCtx(function () {
          return [_withDirectives(_createElementVNode("input", {
            type: "email",
            id: "email",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
              return $data.emailaddress = $event;
            }),
            "class": "dropdown-input",
            placeholder: _this.getTranslatedString('ml_112216'),
            onFocus: _cache[3] || (_cache[3] = function ($event) {
              return $data.isInvalidEmail = false;
            })
          }, null, 40, _hoisted_4), [[_vModelText, $data.emailaddress]]), _this.isInvalidEmail ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_5, _cache[14] || (_cache[14] = [_createTextVNode(" x-Please enter a valid Email address ")]))), [[_directive_t, {
            path: 'ml_114063'
          }]]) : _createCommentVNode("", true), _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1))];
        }),
        _: 1
      })];
    }),
    _: 1
  }), _createVNode(_component_b_row, {
    "align-h": "center"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_b_col, {
        cols: "10",
        md: "6",
        xl: "4"
      }, {
        "default": _withCtx(function () {
          return [_withDirectives(_createElementVNode("input", {
            type: "tel",
            id: "phone",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
              return $data.telephone = $event;
            }),
            "class": "dropdown-input",
            placeholder: _this.getTranslatedString('ml_112217')
          }, null, 8, _hoisted_6), [[_vModelText, $data.telephone]]), _cache[16] || (_cache[16] = _createElementVNode("br", null, null, -1))];
        }),
        _: 1
      })];
    }),
    _: 1
  }), _createVNode(_component_b_row, {
    "align-h": "center"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_b_col, {
        cols: "10",
        md: "6",
        xl: "4",
        "class": "ital"
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("div", _hoisted_7, [_createVNode(_component_vue_recaptcha, {
            "class": "row",
            "sv-show": "showRecaptcha",
            sitekey: "6LfrGh4TAAAAAEvlMreHo4bJd5RTnQ3fFTiE769k",
            size: "normal",
            theme: "light",
            hl: "en",
            "loading-timeout": _ctx.loadingTimeout,
            onVerify: $options.onCaptchaVerified,
            onExpire: $options.onCaptchaExpired,
            onFail: _ctx.recaptchaFailed,
            onError: _ctx.recaptchaError,
            ref: "recaptcha"
          }, null, 8, ["loading-timeout", "onVerify", "onExpire", "onFail", "onError"])]), _createElementVNode("p", null, [_createElementVNode("input", {
            type: "checkbox",
            onClick: _cache[5] || (_cache[5] = function ($event) {
              return $options.userAgrees($event);
            }),
            id: "agree",
            "class": "bigger-checkbox"
          }), _cache[17] || (_cache[17] = _createTextVNode("    ")), _createElementVNode("span", {
            innerHTML: _this.getPrivacyPolicy('ml_114040', 'Privacy Policy'),
            "class": "privacy-text"
          }, null, 8, _hoisted_8)])];
        }),
        _: 1
      })];
    }),
    _: 1
  }), _createVNode(_component_b_row, {
    "align-h": "center"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_b_col, {
        cols: "10",
        md: "6",
        xl: "4",
        "class": "ital"
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("div", {
            "class": _normalizeClass(["send-button", $options.enableDownload]),
            onClick: _cache[6] || (_cache[6] = function ($event) {
              return $options.submitContactForm();
            })
          }, [_withDirectives((_openBlock(), _createElementBlock("span", null, _cache[18] || (_cache[18] = [_createTextVNode(" Get Your Copy")]))), [[_directive_t, {
            path: 'ml_112218',
            locale: _this.$i18n.locale
          }], [_vShow, !$data.isDownloading]]), _withDirectives(_createElementVNode("span", null, _cache[19] || (_cache[19] = [_createElementVNode("img", {
            src: "images/download.gif",
            style: {
              "width": "30px",
              "height": "30px"
            }
          }, null, -1)]), 512), [[_vShow, $data.isDownloading]])], 2)];
        }),
        _: 1
      })];
    }),
    _: 1
  }), _createVNode(_component_b_row, {
    "align-h": "center"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_b_col, {
        cols: "10",
        md: "6",
        xl: "4",
        "class": "ital subtal"
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("p", {
            innerHTML: _this.getPrivacyPolicy('ml_114039')
          }, null, 8, _hoisted_9)];
        }),
        _: 1
      })];
    }),
    _: 1
  })])) : _createCommentVNode("", true);
}