import { $api } from '@/plugins/axios';
import vueRecaptcha from 'vue3-recaptcha2';
export default {
  name: 'Download',
  components: {
    vueRecaptcha: vueRecaptcha
  },
  props: ['shouldRender'],
  mounted: function mounted() {},
  computed: {
    enableDownload: function enableDownload() {
      return {
        agree: this.enabledDownload !== true || this.recaptchaVerified === ''
      };
    }
  },
  data: function data() {
    return {
      enabledDownload: false,
      recaptchaVerified: '',
      isDownloading: false,
      showRecaptcha: true,
      loadingTimeout: 30000 // 30 seconds
    };
  },
  methods: {
    onCaptchaVerified: function onCaptchaVerified(recaptchaToken) {
      debugger;
      this.recaptchaVerified = recaptchaToken;
    },
    onCaptchaExpired: function onCaptchaExpired() {
      debugger;
      this.recaptchaVerified = '';
    },
    getPrivacyPolicy: function getPrivacyPolicy(str) {
      var s = this.$t(str);
      var privacyURL = this.$store.state.userCountryObject.privacypolicy;
      return s.replace('<a href="">', '<a href="' + privacyURL + '" target="_blank">');
    },
    getMatchingMaterial: function getMatchingMaterial() {
      var _this = this;
      var materialNumber = this.$store.state.materialNo.toString().padStart(18, '0');
      var material;
      if (this.$store.state.country === 'OTH') {
        material = {
          Country: 'GB',
          Language: this.$store.state.language.toUpperCase(),
          Material: materialNumber,
          SalesOrg: 'GB03',
          Template: 'MSDS_EUR'
        };
      } else {
        material = this.$store.state.materialResponse.find(function (a) {
          return a.Country === _this.$store.state.country && a.Language === _this.$store.state.language.toUpperCase() && a.Material === materialNumber && a.SalesOrg.startsWith(_this.$store.state.country);
        });
        if (!material) {
          material = this.$store.state.materialResponse.find(function (a) {
            return a.Country === _this.$store.state.country && a.Language === _this.$store.state.language.toUpperCase() && a.Material === materialNumber;
          });
        }
      }
      return material;
    },
    stepFourClick: function stepFourClick() {
      this.$emit('downloadSelected', this.chosen);
      if (this.validateMaterial()) {
        var material = this.getMatchingMaterial();
        this.isDownloading = true;
        this.downloadSDS(material);
        this.$refs.recaptcha.reset();
        this.recaptchaVerified = '';
      } else {
        this.$store.commit('setMaterialIdExist', false);
      }
    },
    validateMaterial: function validateMaterial() {
      if (this.$store.state.materialResponse.length === 0) {
        return false;
      }
      return true;
    },
    getErrorMessage: function getErrorMessage(msg) {
      switch (msg) {
        case 'INVALID_INPUT':
          return 'ml_114074';
        case 'EXCEPTION_OCCURED':
          return 'ml_114077';
        case 'RECAPTCHA_VALIDATION_FAILED':
          return 'ml_114076';
        case 'NO_SDS_FOUND':
          return 'ml_114075';
        default:
          return msg;
      }
    },
    downloadSDS: function downloadSDS(material) {
      var _this2 = this;
      return new Promise(function (resolve, reject) {
        var url = 'sap/doc';
        var request = {
          MaterialData: material,
          ProspectData: null,
          Token: _this2.recaptchaVerified
        };
        $api.post(url, request).then(function (response) {
          if (!response.data.isSuccess) {
            alert(_this2.$t(_this2.getErrorMessage(response.data.errorMsg)));
          } else {
            _this2.downloadFromResponse(response.data.document);
          }
          resolve();
        })["catch"](function (err) {
          console.log(err);
        }).then(function () {
          _this2.isDownloading = false;
        });
      });
    },
    base64ToArrayBuffer: function base64ToArrayBuffer(base64) {
      var binaryString = window.atob(base64);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    },
    downloadFromResponse: function downloadFromResponse(data) {
      var arrrayBuffer = this.base64ToArrayBuffer(data); //data is the base64 encoded string
      var blob = new Blob([arrrayBuffer], {
        type: 'application/pdf'
      });
      var link = window.URL.createObjectURL(blob);
      window.open(link, '', 'height=650,width=840');
    },
    userAgrees: function userAgrees(event) {
      this.enabledDownload = event.target.checked;
    }
  }
};