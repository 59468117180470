import { createApp } from 'vue'
import { createBootstrap } from 'bootstrap-vue-next'
import App from './App.vue'
import store from './store'
import router from '@/router'
import { i18n } from '@/i18n'
import { installaxios } from '@/plugins/axios'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

const app = createApp(App);
app.use(store).use(router).use(i18n).use(installaxios).use(createBootstrap())
//Use the below approach to access route query in App.vue
router.isReady().then(() => {
    app.mount('#app')
})
