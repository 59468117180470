import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, vShow as _vShow, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  "class": "d-flex justify-content-center"
};
var _hoisted_3 = ["innerHTML"];
var _hoisted_4 = {
  "class": "gtm-download-sds"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _this = this;
  var _component_b_row = _resolveComponent("b-row");
  var _component_vue_recaptcha = _resolveComponent("vue-recaptcha");
  var _directive_t = _resolveDirective("t");
  return $props.shouldRender == 'customer' ? (_openBlock(), _createElementBlock("span", _hoisted_1, [_createVNode(_component_b_row, {
    "align-h": "center"
  }, {
    "default": _withCtx(function () {
      return _cache[2] || (_cache[2] = [_createElementVNode("br", null, null, -1)]);
    }),
    _: 1
  }), _createVNode(_component_b_row, {
    "align-h": "center",
    "class": "ital"
  }, {
    "default": _withCtx(function () {
      return [_cache[3] || (_cache[3] = _createElementVNode("p", null, " ", -1)), _createElementVNode("div", null, [_createVNode(_component_vue_recaptcha, {
        "class": "row justify-content-center",
        "sv-show": "showRecaptcha",
        sitekey: "6LfrGh4TAAAAAEvlMreHo4bJd5RTnQ3fFTiE769k",
        size: "normal",
        theme: "light",
        hl: "en",
        "loading-timeout": $data.loadingTimeout,
        onVerify: $options.onCaptchaVerified,
        onExpire: $options.onCaptchaExpired,
        onFail: _ctx.recaptchaFailed,
        onError: _ctx.recaptchaError,
        ref: "recaptcha"
      }, null, 8, ["loading-timeout", "onVerify", "onExpire", "onFail", "onError"])])];
    }),
    _: 1
  }), _createVNode(_component_b_row, null, {
    "default": _withCtx(function () {
      return _cache[4] || (_cache[4] = [_createElementVNode("br", null, null, -1)]);
    }),
    _: 1
  }), _createVNode(_component_b_row, {
    "align-h": "center",
    "class": "ital"
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("p", _hoisted_2, [_createElementVNode("input", {
        type: "checkbox",
        id: "agree",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return $options.userAgrees($event);
        }),
        "class": "bigger-checkbox"
      }), _cache[5] || (_cache[5] = _createTextVNode("    ")), _createElementVNode("span", {
        innerHTML: _this.getPrivacyPolicy('ml_114040', 'Privacy Policy'),
        "class": "privacy-text"
      }, null, 8, _hoisted_3)])];
    }),
    _: 1
  }), _createVNode(_component_b_row), _createVNode(_component_b_row, {
    "align-h": "center"
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("div", {
        "class": _normalizeClass(["download-button gtm-download-sds", $options.enableDownload]),
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return $options.stepFourClick();
        })
      }, [_withDirectives((_openBlock(), _createElementBlock("span", _hoisted_4, _cache[6] || (_cache[6] = [_createTextVNode(" 112211-DOWNLOAD SDS")]))), [[_directive_t, {
        path: 'ml_112211',
        locale: _this.$i18n.locale
      }], [_vShow, !$data.isDownloading]]), _withDirectives(_createElementVNode("span", null, _cache[7] || (_cache[7] = [_createElementVNode("img", {
        src: "images/download.gif",
        style: {
          "width": "30px",
          "height": "30px"
        }
      }, null, -1)]), 512), [[_vShow, $data.isDownloading]])], 2)];
    }),
    _: 1
  })])) : _createCommentVNode("", true);
}