import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0,
  style: {
    "color": "red"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _this = this;
  var _component_vue_simple_suggest = _resolveComponent("vue-simple-suggest");
  var _component_b_col = _resolveComponent("b-col");
  var _component_b_row = _resolveComponent("b-row");
  var _directive_t = _resolveDirective("t");
  return _openBlock(), _createElementBlock("span", null, [_createVNode(_component_b_row, {
    "align-h": "center"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_b_col, {
        cols: "11"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_vue_simple_suggest, {
            "class": "input-elements",
            modelValue: $data.chosen,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return $data.chosen = $event;
            }),
            "max-suggestions": 0,
            list: $options.getList,
            "filter-by-query": true,
            ref: "suggestComponent",
            "onUpdate:modelSelect": $options.updateAppDataOnSelect,
            "min-length": $data.countriesWithOneCharSearch.indexOf(_this.$store.state.userCountryObject.countrycode) > -1 ? 1 : 3
          }, null, 8, ["modelValue", "list", "onUpdate:modelSelect", "min-length"])];
        }),
        _: 1
      })];
    }),
    _: 1
  }), _createVNode(_component_b_row, {
    "align-h": "center"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_b_col, {
        cols: "11"
      }, {
        "default": _withCtx(function () {
          return [!_this.$store.state.isMaterialIdExist ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_1, _cache[1] || (_cache[1] = [_createTextVNode(" x-Material ID not found ")]))), [[_directive_t, {
            path: 'ml_113978',
            locale: _this.$i18n.locale
          }]]) : _createCommentVNode("", true)];
        }),
        _: 1
      })];
    }),
    _: 1
  })]);
}