import { createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, vShow as _vShow, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  id: "app"
};
var _hoisted_2 = {
  id: "ap-wrapper"
};
var _hoisted_3 = {
  id: "ap-canvas"
};
var _hoisted_4 = {
  id: "ap-top-bar",
  "class": "d-none d-lg-block d-md-block d-sm-block"
};
var _hoisted_5 = {
  "class": "left"
};
var _hoisted_6 = ["href"];
var _hoisted_7 = ["href"];
var _hoisted_8 = {
  "class": "right"
};
var _hoisted_9 = ["value"];
var _hoisted_10 = {
  id: "ap-top-menu",
  "class": "d-none d-sm-block"
};
var _hoisted_11 = {
  id: "ap-logo"
};
var _hoisted_12 = ["href"];
var _hoisted_13 = ["src", "alt", "width", "height"];
var _hoisted_14 = {
  id: "my-ap-link"
};
var _hoisted_15 = {
  href: "https://account.airproducts.com/login",
  target: "_blank"
};
var _hoisted_16 = {
  "class": "ap-green ap-my-company"
};
var _hoisted_17 = {
  "class": "breadcrumb nav--breadcrumb",
  style: {}
};
var _hoisted_18 = ["href"];
var _hoisted_19 = ["href"];
var _hoisted_20 = {
  id: "ap-flex"
};
var _hoisted_21 = {
  id: "ap-site-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Layout = _resolveComponent("Layout");
  var _component_Footer = _resolveComponent("Footer");
  var _directive_t = _resolveDirective("t");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_withDirectives((_openBlock(), _createElementBlock("a", {
    href: $options.getCustomerSupport()
  }, _cache[2] || (_cache[2] = [_createTextVNode("Customer Support")]), 8, _hoisted_6)), [[_directive_t, {
    path: 'ml_14943',
    locale: this.$i18n.locale
  }]]), _withDirectives((_openBlock(), _createElementBlock("a", {
    id: "osvc_reg_contactus",
    href: $options.getContactUs()
  }, _cache[3] || (_cache[3] = [_createTextVNode("Contact Us")]), 8, _hoisted_7)), [[_directive_t, {
    path: 'ml_3047',
    locale: this.$i18n.locale
  }]])]), _createElementVNode("div", _hoisted_8, [_withDirectives(_createElementVNode("select", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.selectedLocale = $event;
    }),
    onChange: _cache[1] || (_cache[1] = function ($event) {
      return $options.onLanguageChange();
    }),
    "class": "country-locale-ddl"
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.Languages, function (l) {
    return _openBlock(), _createElementBlock("option", {
      key: l.Code,
      value: l
    }, _toDisplayString(l.NativeName), 9, _hoisted_9);
  }), 128))], 544), [[_vModelSelect, $data.selectedLocale]])])]), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createElementVNode("a", {
    href: $options.getHomeUrl(),
    target: "_blank"
  }, [_createElementVNode("img", {
    src: $options.getLogoUrl(),
    alt: this.selectedCountry.mycompany ? this.selectedCountry.mycompany.concat(' Home') : 'AirProducts Home',
    width: this.selectedCountry.logo ? this.selectedCountry.logo.width : 193,
    height: this.selectedCountry.logo ? this.selectedCountry.logo.height : 34
  }, null, 8, _hoisted_13)], 8, _hoisted_12)]), _withDirectives(_createElementVNode("div", _hoisted_14, [_createElementVNode("a", _hoisted_15, [_cache[4] || (_cache[4] = _createElementVNode("span", {
    "class": "ap-blue"
  }, "My", -1)), _createElementVNode("span", _hoisted_16, _toDisplayString(this.selectedCountry.mycompany ? this.selectedCountry.mycompany : 'AirProducts'), 1)])], 512), [[_vShow, this.selectedCountry.countrycode !== 'IL']])]), _createElementVNode("ul", _hoisted_17, [_createElementVNode("li", null, [_withDirectives(_createElementVNode("a", {
    "class": "link link--default",
    href: $options.getHomeUrl(),
    target: "_blank"
  }, null, 8, _hoisted_18), [[_directive_t, {
    path: 'ml_114041',
    locale: this.$i18n.locale
  }]])]), _createElementVNode("li", null, [_withDirectives((_openBlock(), _createElementBlock("a", {
    "class": "link link--default",
    href: this.allApplicationsNotAdded.indexOf(this.selectedCountry.countrycode) > -1 ? $options.getHomeUrl() : this.selectedCountry.www + '/all-applications',
    target: "_blank"
  }, _cache[5] || (_cache[5] = [_createTextVNode("ml_114042-Applications")]), 8, _hoisted_19)), [[_directive_t, {
    path: 'ml_114042',
    locale: this.$i18n.locale
  }]])]), _withDirectives((_openBlock(), _createElementBlock("li", null, _cache[6] || (_cache[6] = [_createTextVNode("Safety Data Sheets")]))), [[_directive_t, {
    path: 'ml_14942',
    locale: this.$i18n.locale
  }]])]), _cache[7] || (_cache[7] = _createElementVNode("div", {
    "class": "heroposter"
  }, null, -1)), _createElementVNode("div", _hoisted_20, [_createElementVNode("div", _hoisted_21, [_createVNode(_component_Layout, {
    country: this.selectedCountry
  }, null, 8, ["country"])])]), _cache[8] || (_cache[8] = _createElementVNode("div", {
    id: "osvc_inlay",
    className: "live-chat"
  }, null, -1)), _createVNode(_component_Footer, {
    country: this.selectedCountry,
    localeCode: this.selectedLocale.Code
  }, null, 8, ["country", "localeCode"])])])]);
}