import codes from '@/assets/country-codes.json';
export default {
  name: 'CountrySelect',
  components: {},
  computed: {
    apSupportedCountries: function apSupportedCountries() {
      var distinctCountries = [];
      this.$store.state.sdsCountries.forEach(function (c) {
        var co = codes.find(function (code) {
          return code.countrycode.toUpperCase() === c.toUpperCase();
        });
        if (co) {
          distinctCountries.push(co);
        }
      });
      distinctCountries = distinctCountries.sort(function (a, b) {
        return a.country <= b.country ? -1 : 1;
      });
      return distinctCountries;
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.getCountryNames();
    this.setDefaultCountry();
    this.$store.commit('setCountry', this.country.countrycode);
    this.$root.$emit('updateCountryOnCustomerSearch', function (arg) {
      _this.getCountryNames();
      _this.setDefaultCountry();
      _this.$store.commit('setCountry', _this.country.countrycode);
      _this.$root.$emit('updateLanguageonCountryChange', _this.chosen);
    });
  },
  data: function data() {
    return {
      country: {
        CountryCode: 'GB',
        Country: 'United Kingdom'
      },
      countries: []
    };
  },
  methods: {
    onCountrySelection: function onCountrySelection() {
      this.$store.commit('setCountry', this.country.countrycode);
      if (this.$store.state.searchType === 'prospect') {
        this.$root.$emit('resetProduct');
      } else {
        this.$root.$emit('updateLanguageonCountryChange', this.chosen);
      }
      this.$emit('countrySelected');
    },
    getCountryByCode: function getCountryByCode(code) {
      return codes.find(function (item) {
        return item.countrycode === code;
      });
    },
    getCountryNames: function getCountryNames() {
      var _this2 = this;
      var countrylist = [];
      if (this.$store.state.searchType === 'customer' && this.$store.state.materialResponse) {
        countrylist.push(this.getCountryByCode('OTH'));
        this.$store.state.materialResponse.forEach(function (item) {
          var country = _this2.getCountryByCode(item.Country);
          if (!countrylist.find(function (c) {
            return c.countrycode === country.countrycode;
          })) {
            countrylist.push(country);
          }
        });
        countrylist.sort(function (a, b) {
          return a.country <= b.country ? -1 : 1;
        });
        this.countries = countrylist;
      } else {
        this.countries = this.apSupportedCountries;
      }
    },
    setDefaultCountry: function setDefaultCountry() {
      var _this3 = this;
      var selectedCountry = this.countries.find(function (item) {
        return item.countrycode === _this3.$store.state.country;
      });
      var userCountry = this.countries.find(function (item) {
        return item.countrycode === _this3.$store.state.userCountryObject.countrycode;
      });
      if (userCountry) {
        this.country = userCountry;
      } else if (selectedCountry) {
        this.country = selectedCountry;
      } else {
        this.country = this.countries.find(function (c) {
          return c.countrycode === 'OTH';
        });
      }
    }
  }
};