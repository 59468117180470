import { createStore } from 'vuex'

export default createStore({
    state: {
        searchType: 'customer',
        lang: 'en-us',
        materialResponse: '',
        prospectResponse: '',
        filteredResponseData: '',
        country: '',
        language: '',
        userCountryObject: null,
        userLanguage: '',
        materialNo: '',
        sdsCountries: '',
        sdsLanguages: '',
        isMaterialIdExist: true
    },
    mutations: {
        setSearchType(state, value) {
            state.searchType = value
        },
        setLang(state, value) {
            state.lang = value
        },
        setMaterialResponse(state, value) {
            state.materialResponse = value
        },
        setProspectResponse(state, value) {
            state.prospectResponse = value
        },
        setFilteredResponseData(state, value) {
            state.filteredResponseData = value
        },
        setCountry(state, value) {
            state.country = value
        },
        setLanguage(state, value) {
            state.language = value
        },
        setUserCountry(state, value) {
            if (process.env.NODE_ENV !== 'production') {
                value.contactus = !value.contactus.includes("--tst2") ? value.contactus.toString().replace('.custhelp.com', '--tst2.custhelp.com') : value.contactus;
                value.customersupport = !value.customersupport.includes("--tst2") ? value.customersupport.toString().replace('.custhelp.com', '--tst2.custhelp.com') : value.customersupport;
            }
            state.userCountryObject = value
        },
        setUserLanguage(state, value) {
            state.userLanguage = value
        },
        setMaterialNo(state, value){
            state.materialNo = value
        },
        setSdsCountries(state, value) {
            state.sdsCountries = value
        },
        setSdsLanguages(state, value) {
            state.sdsLanguages = value
        },
        setMaterialIdExist(state, value) {
            state.isMaterialIdExist = value
        }
    },
    actions: {
    }
})