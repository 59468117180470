import codes from '@/assets/language-codes.json';
export default {
  name: 'LanguageSelect',
  components: {},
  computed: {
    allLanguages: function allLanguages() {
      var _allLanguages = [];
      this.$store.state.sdsLanguages.forEach(function (l) {
        var lan = codes.find(function (c) {
          return c.Code.toUpperCase() === l;
        });
        if (lan) {
          _allLanguages.push(lan);
        }
      });
      return _allLanguages.sort(function (a, b) {
        return a.Code <= b.Code ? -1 : 1;
      });
    },
    mlString: function mlString() {
      return {};
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.getLanguageNamesForCountry(this.$store.state.userCountryObject.countrycode);
    this.setDefaultLanguage();
    this.$store.commit('setLanguage', this.language.Code);
    this.$root.$emit('updateLanguageonCountryChange', function (arg) {
      _this.getLanguageNamesForCountry(_this.$store.state.country);
      _this.setDefaultLanguage();
      _this.$store.commit('setLanguage', _this.language.Code);
    });
    this.$emit('languageSelected', 'Hi');
  },
  data: function data() {
    return {
      language: {
        Code: 'EN',
        Language: 'English'
      },
      languages: [{
        Code: 'EN',
        Language: 'English'
      }, {
        Code: 'FR',
        Language: 'French'
      }]
    };
  },
  methods: {
    onLanguageSelection: function onLanguageSelection() {
      this.$store.commit('setLanguage', this.language.Code);
      this.$emit('languageSelected');
      if (this.$store.state.searchType === 'prospect') {
        this.$root.$emit('resetProduct');
      }
    },
    getLanguageByCode: function getLanguageByCode(code) {
      return codes.find(function (item) {
        return item.Code.toLowerCase() === code.toLowerCase();
      });
    },
    getLanguageNamesForCountry: function getLanguageNamesForCountry(countrycode) {
      var _this2 = this;
      if (countrycode === 'OTH') {
        this.languages = this.allLanguages;
        return;
      }
      var languagelist = [];
      if (this.$store.state.searchType === 'prospect' && this.$store.state.prospectResponse && this.$store.state.prospectResponse.Items.length > 0) {
        this.$store.state.prospectResponse.Items.forEach(function (item) {
          var language = _this2.getLanguageByCode(item.LANGUAGE.S);
          if (language && !languagelist.find(function (l) {
            return l.Code === language.Code;
          })) {
            languagelist.push(language);
          }
        });
        languagelist.sort(function (a, b) {
          return a.Code <= b.Code ? -1 : 1;
        });
        this.languages = languagelist;
      } else if (this.$store.state.searchType === 'customer' && this.$store.state.materialResponse.length > 0) {
        var materialDataForCountry = this.$store.state.materialResponse.filter(function (a) {
          return a.Country === countrycode;
        });
        materialDataForCountry.forEach(function (item) {
          var language = _this2.getLanguageByCode(item.Language);
          if (language && !languagelist.find(function (l) {
            return l.Code === language.Code;
          })) {
            languagelist.push(language);
          }
        });
        languagelist.sort(function (a, b) {
          return a.Code <= b.Code ? -1 : 1;
        });
        this.languages = languagelist;
      } else {
        this.languages = this.allLanguages;
      }
    },
    setDefaultLanguage: function setDefaultLanguage() {
      var _this3 = this;
      var selectedLanguage = this.languages.find(function (item) {
        return item.Code === _this3.$store.state.language;
      });
      var userLanguageSimplified = this.$store.state.userLanguage === 'pt-BR' ? 'z1' : this.$store.state.userLanguage.split('-')[0];
      var userLanguage = this.languages.find(function (item) {
        return item.Code === userLanguageSimplified;
      });
      if (selectedLanguage) {
        this.language = selectedLanguage;
      } else if (userLanguage) {
        this.language = userLanguage;
      } else {
        this.language = this.languages.find(function (item) {
          return item.Code === 'en';
        });
      }
      this.$store.commit('setLanguage', this.language.Code);
    }
  }
};