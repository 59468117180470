import { createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  "class": "heading"
};
var _hoisted_2 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _this = this;
  var _component_b_col = _resolveComponent("b-col");
  var _component_b_row = _resolveComponent("b-row");
  var _directive_t = _resolveDirective("t");
  return _openBlock(), _createBlock(_component_b_row, null, {
    "default": _withCtx(function () {
      return [_createVNode(_component_b_col, {
        cols: "12"
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("div", {
            "class": "calltoarms",
            style: _normalizeStyle(_this.$store.state.userLanguage === 'he-IL' ? 'text-align: right' : 'text-align: left')
          }, [_withDirectives((_openBlock(), _createElementBlock("h1", _hoisted_1, _cache[0] || (_cache[0] = [_createTextVNode("x - Safety Data Sheets Library")]))), [[_directive_t, {
            path: 'ml_112201'
          }]]), _createElementVNode("span", {
            innerHTML: _this.$t('ml_112203', 'intro para')
          }, null, 8, _hoisted_2)], 4)];
        }),
        _: 1
      })];
    }),
    _: 1
  });
}